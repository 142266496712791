import React from 'react'

import * as lazyAnimationIndexStyles from './lazeeAnimationIndex.module.css'

const LazyAnimationIndex = (props) => {
  return (
    <div className={lazyAnimationIndexStyles.imageAnimation}>
        {props.children}
    </div>
  );
};

export default LazyAnimationIndex;
